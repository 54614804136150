@import "variables";
@import "../../../../shared/mediaQuery";


#publicLayout {
  display: flex;
  flex-direction: column;
  background: $bgGradientPrimary;
  height: 100vh;
  align-items: center;
  justify-content: center;

  >.login-content {
    width: $widthPublicLayout;
    background-image: $bgGradientPublicContainer;
    border: $borderPublicContainer;
    box-shadow: $boxShadowPublicContainer;
    border-radius: $borderRadiusPublicContainer;
    padding: $paddingPublicContainer;

    >div {
      width: 340px;
      margin: 0 auto;
    }
  }

  .img-content {
    text-align: center;
  }
}

@media (max-width: $lg-device) {
  #publicLayout {

    >.login-content {
      width: 90%;

      >div {
        width: 100%;
        margin: 0 auto;
      }

    }

  }
}