$bgTHeader: #f5f6f9 !default;
$colorTHeader: #241d8c !default;

$borderColorTRow: transparent; //#d0e0ed !default;
$colorTRow: #445167d9 !default;
$colorTRowActive: #7ed321 !default;

$radius: 12px;
$bgTBody: #f5f8fb !default;
$fontFamilyTHeader: "Helvetica-bold" !default;
$fontSizeTHeader: 12px !default;
$fontSizeTBody: 16px !default;