@import "variables";
@import "../../../shared/mediaQuery";

.caption {
  font-family: $fontFamilyCaptionRegular;
  font-size: $fontSizeCaption;
  color: $colorCaption;
  letter-spacing: $letterSpacingCaption;
  line-height: $lineHeightCaption;
  opacity: 1;
  display: flex;
  align-items: center;
  @media (max-width: $lg-device) {
    font-size: $fontSizeCaptionMobile;
  }

  &.light {
    font-family: $fontFamilyCaptionRegular;
    font-weight: 400;
  }
  &.medium {
    font-family: $fontFamilyCaptionMedium;
  }
  &.medium-link {
    font-family: $fontFamilyCaptionMedium;
    color: $primary;
  }
  &.emphasis {
    font-family: $fontFamilyCaptionEmphasis;
  }
  &.captionOpacity {
    opacity: 0.65;
  }
  &.link {
    color: $primary;
  }
  &.error {
    color: $error;
  }
  &.reverse {
    color: #fff;
  }
  &.sm {
    font-size: $fontSizeCaptionMobile;
  }
}
