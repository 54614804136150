@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "../components/components";
@import "features";

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}
.main-auth {
  margin-top: 120px;

  @media screen and (max-width: 990px) {
    margin-top: 120px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 120px;
  }
}

@media print {
  @page {
    margin:0.2cm;
  }
  .page-break { page-break-before: always; }
  body {
    font-size: 0.7em;
  }

  .card-package {
    padding: 0;
  }

  .card-package .container {
    padding: 0;
  }

  .main-auth {
    margin: 0;
    padding: 0;
  }
  .container{
    max-width: 100%;
  }
  .hide_print {
    display: none !important;
  }
}

.pointer {
  cursor: pointer;
}

.no-permission {
  padding: 80px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
