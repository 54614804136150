@import "variables";

.shared-icon {
  font-family: "icon-pack";
  line-height: normal;
  padding: 4px;
  display: flex;
  justify-content: center;
  & .icon {
    &::before {
      color: var(--color-icon-var);
    }
  }
  & .disabled {
    &::before {
      opacity: 0.3;
    }
  }
  /*   & .primary {
    &::before {
      color: $primary;
    }
  } */
  /* 
  & .primary {
    &::before {
      color: $primary;
    }
  }
  & .default {
    &::before {
      color: $default;
    }
  }
  & .gray {
    &::before {
      color: $gray;
    }
  }
  & .orange {
    &::before {
      color: $orange;
    }
  }
  & .green {
    &::before {
      color: $green;
    }
  }
  & .red {
    &::before {
      color: $red;
    }
  }
  & .black {
    &::before {
      color: $black;
    }
  }
  & .white {
    &::before {
      color: $white;
    }
  } */
  .iconLabel {
    margin-left: 8px;
    font-family: "Helvetica-bold";
    font-size: 16px;
    color: var(--color-icon-var);
  }
}
.shared-iconHelper {
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
    font-family: "Helvetica-bold";
    font-size: 12px;
    color: #565b6f;
  }
}
.shared-bigIconBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  .icon {
    background: #3e81b9;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      background: #d0e0ed;
      color: #565b6f;
      &::before {
        opacity: 0.5;
        color: #fff;
        position: relative;
      }
    }
    &::before {
      color: var(--color-icon-var);
    }
  }
  &.noBg {
    & .icon {
      background-color: transparent;
    }
  }
  .iconLabel {
    font-family: "Helvetica";
    font-size: 16px;
    color: #3e81b9;
    margin: 0px;
    &.disabled {
      color: #565b6f;
    }
  }
}
