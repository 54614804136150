@import "variables";

.shared-toast {
  margin-bottom: 4px;

  .toast {
    border-radius: $border-radius;

    & .toast-header {
      min-height: 40px;
      font-size: 16px;
      background-color: transparent;
      padding: 12px;
      border-radius: $border-radius;
      width: 100%;

      & strong {
        width: 100%;
        
      }

      & .close {
        color: #fff;
        background: none;
        border: none;
        margin-left: auto;
        font-weight: bold;

        &.ml-2 {
          margin-left: 16px !important;
        }
      }
    }

    & .toast-body {
      padding: 12px 24px;
    }
  }

  transition: all 0.4s ease-in-out;

  &.in {
    transform: translateX(0px);
  }

  &.out {
    transform: translateX(400px);
  }
}