$fontSizeCaption: 16px !default;
$fontSizeCaptionMobile: 12px !default;
$fontFamilyCaptionRegular: "Helvetica" !default;
$fontFamilyCaptionMedium: "Helvetica" !default;
$fontFamilyCaptionEmphasis: "Helvetica-italic" !default;
$primary: #0393f3 !default;
$error: #a10000 !default;
$colorCaption: #445167d9 !default;
$letterSpacingCaption: 0 !default;
$lineHeightCaption: 14px !default;
