$bgTitleAccordion: #fff !default;
$colorTitleAccordion: #212594 !default;
$heightTitleAccordion: 32px !default;
$fontSizeTitleAccordion: 14px !default;
$letterSapcingTitleAccordion: 1px !default;
$borderRadiusTitleAccordion: 8px !default;
$minWidthTitleAccordion: 250px !default;

$fontFamilyIconDownAccordion: "icon-pack" !default;
$fontSizeIconDownAccordion: 23px !default;
$iconDownAccordion: "\e926" !default;