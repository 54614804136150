@import "components/publicLayout/index";
@import "components/Header";
@import "components/Footer";

.containerApp {
  margin-top: 110px;
  min-height: calc(100vh - 234px);

  padding-bottom: 32px;

  @media print {
    margin: 0;
  }
}