@import "variables";
@import "../../../shared/mediaQuery";

.navbar {
  &.navGradient {
    background-image: $bgGradientPrimary;
    min-height: $navbarHeight;
    z-index: 6000;
    .nav-link {
      color: #fff;

      &:hover {
        color: #fff6;
      }
    }

    .logo-brand {
      background-color: #fff;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      padding: 8px;
      cursor: pointer;
    }

    .img-user {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
  }

  .dropdown-item {
    color: #212594;
  }

  .nav-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .nav-item-drop {
    display: flex;
  }

  .nav-user-drop {
    display: flex;
    align-items: center;
  }
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #fff6;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #fff6;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
}

@media (max-width: $md-device) {
  .navbar {
    .nav-item-content {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
    }

    .nav-item-drop {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 4px;
    }

    .nav-user-drop {
      margin-top: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      img {
        margin-right: 8px;
      }
    }

    .nav-item.dropdown.show {
      width: 100%;
    }
  }
}
