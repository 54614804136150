@import "variables";

.shared-accordion {
  width: 100%;

  .title {
    width: 100%;
    background: $bgTitleAccordion;
    color: $colorTitleAccordion;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border-radius: $borderRadiusTitleAccordion;
    font-size: 18px;

    /*//position: absolute;
    top: -$heightTitleAccordion;
    left: 0px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $fontSizeTitleAccordion;
    letter-spacing: $letterSapcingTitleAccordion;
    height: $heightTitleAccordion;
    min-width: $minWidthTitleAccordion;
    line-height: initial;
    border-top-left-radius: $borderRadiusTitleAccordion;
    border-top-right-radius: $borderRadiusTitleAccordion;
    border-bottom-left-radius: $borderRadiusTitleAccordion;
    border-bottom-right-radius: $borderRadiusTitleAccordion;
    max-width: 100%;
    & span {
      width: 100%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 900;
    }
    & .number {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: right;
      background-color: #fff;
      color: #2b2e32;
      width: auto;
      min-width: 20px;
      border-radius: 2px;
      padding: 8px 16px;
      margin-left: 6px;
    } */
    & .label-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  &:not(.not-padding) {
    .collapse {
      padding: 0px 10px;
    }
  }

  .collapse {
    //border: 1px solid #0000001a;
  }
}

.box {
  & .card-body {
    min-height: 0px;
    padding: 0px;
  }
}