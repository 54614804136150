$widthButton: 100% !default;

$heightButton: 38px !default;
$heightMediumButton: 36px !default;
$heightSmallButton: 30px !default;

$fontFamilyButton: "Helvetica" !default;

$fontSizeButton: 16px !default;
$fontSizeMediumButton: 16px !default;
$fontSizeSmallButton: 16px !default;

$lineheightMediumButton: 24px !default;
$lineheightSmallButton: 19px !default;

$borderRadiusButton: 12px !default;
