@import "variables";

.shared-dataInput {
  height: $heightInputDate;

  &.form-control {
    background-color: #fff;
    border-radius: 12px;
  }

  &:focus {
    box-shadow: none;
  }
}

/* input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

  &:before {
    color: transparent;
    background: none;
    display: block;
    font-family: $fontFamilyIconInputDate;
    content: $iconInputDate;
    color: $colorInputDate;
    position: absolute;
    right: 12px;
  }
} */

.md {
  height: $heightMediumInputDate;
  font-size: $fontSizeMediumInputDate;
  line-height: $lineheightMediumInputDate;
}

.sm {
  height: $heightSmallInputDate;
  font-size: $fontSizeSmallInputDate;
  line-height: $lineheightSmallInputDate;
}