.card-package {
    background: #fff;
    padding: 25px;

    .container {
        padding-bottom: 42px;
        border-bottom: 2px solid #000;
    }

    .left-side {
        .content-left-side {
            margin-left: 25px;
            margin-top: -20px;
            line-height: 28px;
        }

        .tag-rotate {
            top: 25px;
        }
    }
    .ellipse-text{
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ellipse-text-riv{
        max-width: 100%;
        font-size: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .right-side {
        .content-right-side {
            margin-left: 40px;
            margin-top: -20px;
            line-height: 28px;
        }

        .tag-rotate {
            top: 40px;
        }
    }

    .detail-content {
        span {
            margin-right: 42px;
        }
    }
}

.tag-rotate {
    display: inline-block;
    transform: rotate(-90deg);
    position: relative;
    left: -20px;
    font-weight: bold;
}
