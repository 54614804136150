@import "variables";

.shared-textArea {
  width: $widthTextArea;
  height: $heightTextArea;
  border-radius: $borderRadiusTextArea;
  font-size: $fontSizeTextArea;
  font-family: $fontFamilyTextArea;
  &.form-control {
    background-color: #fff;
    border-radius: 12px;
  }
  &.error {
    border-color: $error;
  }
}
