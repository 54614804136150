.package-item {

    flex-basis: 250px;
    //border: 1px solid #1160c9;
    border-radius: 12px;
    padding: 25px;
    position: relative;
    margin-right: 12px;
    margin-bottom: 12px;
    background-color: rgba(255, 255, 255, 0.90);

    & .header-p {
        margin: -20px;
        margin-bottom: 0px;
        display: flex;

    }

    .form-group {
        height: 60px;

     
    }

    .round-p {
        background-color: #12a300;
        border-radius: 50%;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

        &.x10 {
            height: 26px;
            width: 26px;
        }
    }

    & .footer-p {
        margin: -20px;
        margin-top: 0px;
    }
}