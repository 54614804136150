$primary: #212594;
$success: #7ed321;
$error: #a10000;
$warning: #ffcd00;
$secondary: #ffffff;

$bg-1: #fff;
$bg-2: #f5f6f9;
$bg-3: #fafafa;
$bg-4: #c9cbd9;

$theme-colors: ("primary": $primary,
  "secondary": $secondary,
);

$body-bg: $bg-2;
$font-family-base: "Helvetica";
$bgGradientPrimary: linear-gradient(212deg, #0091f6 0%, #241d8c 100%);
$bgGradientSecondary: linear-gradient(212deg, #ffffff 0%, #ddedfa 100%);

$font-family-regular: "Helvetica";
$font-family-bold: "Helvetica";
$font-family-medium: "Helvetica";
$font-family-light: "Helvetica-light";

$form-group-margin-bottom: 4px;
$input-placeholder-color: #44516773;

$container-max-widths: (sm: 540px,
  md: 960px,
  lg: 1024px,
  xl: 1440px,
);