.footer {
  margin-top: 20px;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 0;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  padding: 4px 0px;

  a {
    text-decoration: none;
  }
}