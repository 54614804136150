$heightInputDate: 38px !default;
$heightMediumInputDate: 36px !default;
$heightSmallInputDate: 30px !default;

$fontFamilyInputDate: "Helvetica" !default;

$fontSizeInputDate: 16px !default;
$fontSizeMediumInputDate: 16px !default;
$fontSizeSmallInputDate: 16px !default;

$lineheightMediumInputDate: 24px !default;
$lineheightSmallInputDate: 19px !default;

$fontFamilyIconInputDate: "onewelf" !default;
$iconInputDate: "\e923" !default;
$colorInputDate: #565b6f !default;
