@import "variables";
.shared-contentBoxEmpty {
  min-height: 124px;
  & span {
    font-family: "Helvetica";
  }
  &.bg::before {
    content: "\e901";
    font-family: "onewelf";
    position: absolute;
    font-size: 124px;
    opacity: 0.1;
  }
}
