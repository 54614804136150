.row-package {
    padding: 4px;
    border-radius: 12px;
    border: 1px solid rgb(205, 205, 205);
}

.content-package-state {
    padding: 20px;
    //border: 1px solid rgb(205, 205, 205);
    background-color: #fff;
    border-radius: 12px;
}