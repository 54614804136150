@import "variables";
@import "../../../shared/mediaQuery";

.heading-2{
  font-family: $fontFamilyHeading2;
  font-size: $fontSizeHeading2;
  color: $colorHeading2;
  letter-spacing: $letterSpacingHeading2;
  line-height: $lineHeightHeading2;
 @media (max-width: $lg-device) {
    font-size: $fontSizeHeading2Mobile;
  } 
}
