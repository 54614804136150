@import "variables";
.shared-button.sh-secondary {
  border: $borderSecondaryButton;
  color: $colorSecondaryButton;
  &:disabled {
    color: #666;
    border-color: #666;
    opacity: 0.2;
  }
}
