.shared-emptyRow {
    background-color: #fff;
    border-radius: 12px;
    padding: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i{
        opacity: 0.2;
    }
}