.stats {
    background-color: #fff;
    border-radius: 12px;
    padding: 12px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #241d8c;
}
.stats-light {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
