$fontSizeText: 24px !default;
$fontSizeTextMobile: 16px !default;
$fontFamilyTextHeavy: 'Helvetica-heavy' !default;
$fontFamilyTextMedium: 'Helvetica' !default;
$fontFamilyTextRegular: 'Helvetica' !default;
$fontFamilyTextEmphasis: 'Helvetica-italic' !default;
$primary: #0393F3 !default;
$error: #A10000 !default;
$colorText: #445167d9 !default;
$letterSpacingText: 0 !default;
$lineHeightText: 19px !default;

